import React from "react";
import {BUILD_TARGET, getTargetInfo} from "../const";
import SelectBox from "../input/SelectBox";
import {Modal, Table} from "antd";
import {httpPost, httpPut, httpUrl} from "../api/httpClient";

export class AccountInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverUrl: undefined,
      yBearerToken: undefined,
      inputBearerToken: '',
      result: { yId: '', yPw: '', 로그_저장_여부: '', y웹소켓_핑_간격_분: '', y주문_폴링_간격_분: ''},
      metadata: { frName: '', frId: '', frIdx: ''},
      results: [],
      targetBranchIdx: '',
      targetFrId: '',
      branchIdx: 0,
      yId: '',
      yPw: '',
      frName: '',
      frId: '',
      frIdx: '',
      isLogSave: '',
      pingDurationMin: '',
      pollingDurationMin: '',
    }
  }

  onChangeBranchIdx = (value) => {
    this.setState({
      result: { yId: '', yPw: '', 로그_저장_여부: '', y웹소켓_핑_간격_분: '', y주문_폴링_간격_분: ''},
      metadata: { frName: '', frId: '', frIdx: ''},
      results: [],
      targetBranchIdx: value,
      targetFrId: '',
      branchIdx: value,
      yId: '',
      yPw: '',
      frName: '',
      frId: '',
      frIdx: '',
      isLogSave: '',
      pingDurationMin: '',
      pollingDurationMin: '',
    })
  }

  onChangeTargetServer = (value) => {
    const targetInfo = getTargetInfo(value);
    this.setState({
      serverUrl : targetInfo.serverUrl,
      yBearerToken: targetInfo.yBearerToken,
      result: { yId: '', yPw: '', 로그_저장_여부: '', y웹소켓_핑_간격_분: '', y주문_폴링_간격_분: ''},
      metadata: { frName: '', frId: '', frIdx: ''},
      results: [],
      targetBranchIdx: '',
      targetFrId: '',
      branchIdx: 0,
      yId: '',
      yPw: '',
      frName: '',
      frId: '',
      frIdx: '',
      isLogSave: '',
      pingDurationMin: '',
      pollingDurationMin: '',
    })
  }

  onChangeInputToken = (value) => {
    this.setState({
      inputBearerToken: value
    })
  }

  checkTargetServerInfo = () => {
    if (this.state.serverUrl === undefined ||
        this.state.yBearerToken === undefined) {
      Modal.info({
        title: '',
        content: 'target server 정보를 선택해주세요.'
      })
      return false;
    }

    if (this.state.inputBearerToken === '' ||
        this.state.inputBearerToken !== this.state.yBearerToken) {
      Modal.info({
        title: '',
        content: 'yBearerToken 정보를 정확히 입력 해주세요.'
      })
      return false;
    }

    return true;
  }

  onCopyData = () => {
    this.setState({
      yId: this.state.result.yId === undefined ? '' : this.state.result.yId,
      yPw: this.state.result.yPw === undefined ? '' : this.state.result.yPw,
      frName: this.state.metadata.frName === undefined ? '' : this.state.metadata.frName,
      frId: this.state.metadata.frId === undefined ? '' : this.state.metadata.frId,
      frIdx: this.state.metadata.frIdx === undefined ? '' : this.state.metadata.frIdx,
      isLogSave: this.state.result.로그_저장_여부 === undefined ? '' : this.state.result.로그_저장_여부,
      pingDurationMin: this.state.result.y웹소켓_핑_간격_분 === undefined ? '' : this.state.result.y웹소켓_핑_간격_분,
      pollingDurationMin: this.state.result.y주문_폴링_간격_분 === undefined ? '' : this.state.result.y주문_폴링_간격_분,
    })
  }

  putData = () => {
    if (!this.checkTargetServerInfo()) {
      return;
    }

    if (this.state.branchIdx === 0 ||
        this.state.yId === '' ||
        this.state.yPw === '' ||
        this.state.frName === '' ||
        this.state.frId === '' ||
        this.state.frIdx === '') {
      Modal.info({
        title: '',
        content: '정보를 입력해주세요.'
      })
      return;
    }
    let isLogSave = this.state.isLogSave;
    let pingDurationMin = this.state.pingDurationMin;
    let pollingDurationMin = this.state.pollingDurationMin;
    if (this.state.isLogSave === '' || (this.state.isLogSave !== 'true' && this.state.isLogSave !== 'false')) {
      isLogSave = false;
    }
    if (this.state.pingDurationMin === '' || isNaN(this.state.pingDurationMin)) {
      pingDurationMin = 8;
    }
    if (this.state.pollingDurationMin === '' || isNaN(this.state.pollingDurationMin)) {
      pollingDurationMin = 5;
    }
    const data = {
      branchIdx: this.state.branchIdx,
      yId: this.state.yId,
      yPw: this.state.yPw,
      frName: this.state.frName,
      frId: this.state.frId,
      frIdx: this.state.frIdx,
      로그_저장_여부: isLogSave,
      y웹소켓_핑_간격_분: pingDurationMin,
      y주문_폴링_간격_분: pollingDurationMin,
    }
    httpPut(this.state.serverUrl, this.state.inputBearerToken, httpUrl.infoPut, data).then((res => {
      if (res.result === 'success') {
        Modal.info({
          title: '',
          content: '추가 완료'
        })
      } else {
        Modal.info({
          title: '',
          content: '추가 실패'
        })
      }
    }))
  }

  getData = () => {
    if (!this.checkTargetServerInfo()) {
      return;
    }

    if (this.state.targetBranchIdx === '') {
      Modal.info({
        title: '',
        content: 'BranchIdx를 입력해주세요.'
      })
      return;
    }

    if (this.state.targetFrId === '') {
      Modal.info({
        title: '',
        content: 'FrId를 입력해주세요.'
      })
      return;
    }

    const data = {
      branchIdx: this.state.targetBranchIdx,
      frId: this.state.targetFrId,
    };
    httpPost(this.state.serverUrl, this.state.inputBearerToken, httpUrl.infoGet, data).then((res => {
      this.setState({
        result: { yId: '', yPw: '', 로그_저장_여부: '', y웹소켓_핑_간격_분: '', y주문_폴링_간격_분: ''},
        metadata: { frName: '', frId: '', frIdx: ''},
      })
      if (res.value != null) {
        this.setState({
          result: res.value,
        });
      }
      if (res.metadata != null) {
        this.setState({
          metadata: res.metadata,
        });
      }

    }))
  };

  getList = () => {
    if (!this.checkTargetServerInfo()) {
      return;
    }

    const data = {
      branchIdx: this.state.branchIdx,
    }
    httpPost(this.state.serverUrl, this.state.inputBearerToken, httpUrl.listGet, data).then((res => {
      this.setState({
        results: res.result,
      })
    }));
  };

  getIsLogSave = () => {
    if (this.state.result.로그_저장_여부 === true) {
      return 'true';
    } else if (this.state.result.로그_저장_여부 === false){
      return 'false';
    }
    return '';
  }

  render() {
    const column = [
      {
        title: '정보',
        dataIndex: 'name',
        className: 'tableValues'
      }
    ];
    return (
        <div className='App-rayout'>
          <SelectBox
              code={Object.keys(BUILD_TARGET)}
              codeString={BUILD_TARGET}
              onChange={(value) => {
                this.onChangeTargetServer(value);
              }}
          ></SelectBox>
          <label> yBearerToken: </label>
          <input
              style={{width: '1500px'}}
              onChange={(e) => this.onChangeInputToken(e.target.value)}>
          </input>
          <div className='App-header-content'>
            <div className='App-rayout'>
              <div style={{fontSize: '20px', paddingBottom: '10px'}}>yId, yPw 입력</div>
              <label> branchIdx: </label>
              <input value={this.state.branchIdx} onChange={(e) => this.setState({ branchIdx: e.target.value })}></input>
              <label> yId: </label>
              <input value={this.state.yId} onChange={(e) => this.setState({ yId: e.target.value })}></input>
              <label> yPw: </label>
              <input value={this.state.yPw} onChange={(e) => this.setState({ yPw: e.target.value })}></input>
              <label> frName: </label>
              <input value={this.state.frName} onChange={(e) => this.setState({ frName: e.target.value })}></input>
              <label> frId: </label>
              <input value={this.state.frId} onChange={(e) => this.setState({ frId: e.target.value })}></input>
              <label> frIdx: </label>
              <input value={this.state.frIdx} onChange={(e) => this.setState({ frIdx: e.target.value })}></input>
              <div></div>
              <label> 로그 저장 여부: </label>
              <input value={this.state.isLogSave} onChange={(e) => this.setState({ isLogSave: e.target.value })}></input>
              <label> y웹소켓 핑 간격(분): </label>
              <input value={this.state.pingDurationMin} onChange={(e) => this.setState({ pingDurationMin: e.target.value })}></input>
              <label> y주문 폴링 간격(분): </label>
              <input value={this.state.pollingDurationMin} onChange={(e) => this.setState({ pollingDurationMin: e.target.value })}></input>

              <button style={{marginLeft: '10px'}} onClick={() => {
                this.putData();
              }}> 입력 </button>
            </div>
          </div>
          <div className='App-header-content'>
            <div>
              <div className='App-rayout'>
                <div style={{fontSize: '20px'}}>yId, yPw 조회</div>
                <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
                  <label>branchIdx: </label>
                  <input
                      value={this.state.targetBranchIdx}
                      onChange={(e) => this.setState({ targetBranchIdx: e.target.value })}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') this.getData();}}>
                  </input>
                  <label> frId: </label>
                  <input
                      value={this.state.targetFrId}
                      onChange={(e) => this.setState({ targetFrId: e.target.value })}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') this.getData();}}>
                  </input>
                  <button style={{marginLeft: '10px'}} onClick={() => {
                    this.getData();
                  }}> 검색 </button>
                  <button style={{marginLeft: '10px'}} onClick={() => {
                    this.onCopyData();
                  }}> 입력 필드에 복사 </button>
                </div>

                <div><label className='App-label'>yId</label>
                  <div className='App-content'>{this.state.result.yId}</div>
                </div>
                <div><label className='App-label'>yPw</label>
                  <div className='App-content'>{this.state.result.yPw}</div>
                </div>
                <div><label className='App-label'>로그 저장 여부</label><div className='App-content'>{this.getIsLogSave()}</div></div>
                <div><label className='App-label'>y웹소켓 핑 간격(분)</label><div className='App-content'>{this.state.result.y웹소켓_핑_간격_분}</div></div>
                <div><label className='App-label'>y주문 폴링 간격(분)</label><div className='App-content'>{this.state.result.y주문_폴링_간격_분}</div></div>
                <div><label className='App-label'>frName</label><div className='App-content'>{this.state.metadata.frName}</div></div>
                <div><label className='App-label'>frId</label><div className='App-content'>{this.state.metadata.frId}</div></div>
                <div><label className='App-label'>frIdx</label><div className='App-content'>{this.state.metadata.frIdx}</div></div>
              </div>
              <div style={{paddingTop: '20px'}}></div>
              <div className='App-rayout'>
                <div style={{fontSize: '20px', paddingBottom: '10px'}}>yId, yPw LIST</div>
                <label>branchIdx: </label>
                <input
                    onChange={(e) => this.onChangeBranchIdx(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') this.getList();}}>
                </input>
                <button style={{marginLeft: '10px'}} onClick={() => {
                  this.getList();
                }}> 검색 </button>

                <Table
                    className='dataTableLayout'
                    dataSource={this.state.results}
                    columns={column}
                    pagination={false}
                    rowKey={(record) => record.name}
                    onRow={(record) => {
                      return {
                        onDoubleClick: () => {
                          let data = record.name.split(':');
                          data = data.map(element => element.trim());
                          this.setState({
                            targetBranchIdx: data[1],
                            targetFrId: data[2],
                          }, () => this.getData())
                        }
                      }
                    }}>
                </Table>
              </div>
            </div>
          </div>
        </div>
    )
  }

}
