import Axios from "axios";

const httpPut = (targetServer, yBearerToken, url, data) => {
  return new Promise((resolve, reject) => {
    Axios.put(`${targetServer}${url}`, data, {
      headers: {
        Authorization: yBearerToken,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
};

const httpPost = (targetServer, yBearerToken, url, data) => {
  return new Promise((resolve, reject) => {
    Axios.post(`${targetServer}${url}`, data, {
      headers: {
        Authorization: yBearerToken,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
};


const httpUrl = {

  // yId, yPw 입력/수정
  infoPut: "/yId/put",

  // yId, yPw 조회
  infoGet: "/yId/get",

  // yId, yPw List 조회
  listGet: "/yId/list",

};

export {
  httpUrl,
  httpPut,
  httpPost,
};
