import './App.css';
import {AccountInfo} from "./pages/AccountInfo";

function App() {
  return (
      <div>
        <div className='App-header-content'>
          <AccountInfo/>
        </div>
      </div>
  );
}


export default App;
