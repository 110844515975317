const BUILD_TARGET = {
  NNBOX_STAGE: 'stage',
  NNBOX_LIVE: 'live',
};

const getTargetInfo = (buildTarget) => {
  switch (buildTarget) {
    case 'NNBOX_STAGE': {
      return {
        serverUrl: 'https://nnbox-yang-staging.sbinc-nyamnyambox.workers.dev',
        yBearerToken: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJubmJveC15YW5nIiwibmFtZSI6ImhlZXdvb2siLCJpYXQiOjE4OTM0MjM2MDB9.YJ6GRW6s1YjNSAXLtbOT3p8C21fjcIg1vYEjIK8cBeU',
      };
    }
    case 'NNBOX_LIVE': {
      return {
        serverUrl: 'https://nnbox-yang.sbinc-nyamnyambox.workers.dev',
        yBearerToken: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJubmJveC15YW5nIiwibmFtZSI6ImhlZXdvb2siLCJpYXQiOjE4OTM0MjM2MDB9.YJ6GRW6s1YjNSAXLtbOT3p8C21fjcIg1vYEjIK8cBeU',
      };
    }

    default:
      return undefined;
  }
};

export { BUILD_TARGET, getTargetInfo };
