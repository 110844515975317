import { Select } from 'antd';
import React, { Component } from 'react';

const Option = Select.Option;

export default class SelectBox extends Component {
  state = {
    dataType: this.props.codeType,
  };

  renderOption = () => {
    const { codeString, options } = this.props;
    if (codeString != null) {
      let result = [];
      for (const property in codeString) {
        if (codeString[property] === '') continue;
        result.push(
          <Option key={`${property}`} value={property}>
            {codeString[property]}
          </Option>
        );
      }
      return result;
    } else if (options != null) {
      return options.map((option) => {
        return (
          <Option key={option.index} value={option.index}>
            {option.label}
          </Option>
        );
      });
    }
  };

  render() {
    const {
      data,
      size,
      width,
      mode,
      style,
      placeholder,
      value,
      type,
      page,
      onChange,
      disable,
      className,
    } = this.props;
    return (
      <>
        <Select
          className={`desk ${className}`}
          size={size}
          mode={mode}
          disabled={disable}
          style={{ ...(width == null && { width: '100px' }), ...style }}
          placeholder={placeholder}
          value={value}
          onChange={(value) => {
            if (type) {
              onChange(value, type);
            } else if (data) {
              if (page) {
                onChange(value, data, page);
              } else onChange(value, data);
            } else {
              onChange(value);
            }
          }}>
          {this.renderOption()}
        </Select>
      </>
    );
  }
}
/**
 *
 */
